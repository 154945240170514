@blueColor: rgba(119, 158, 185, 1);
@grayColor: rgba(252, 244, 244, 0.4);
@fail-color: rgb(247, 179, 179);
@almost-success-color: rgb(116, 193, 237);
@success-color: rgb(139, 203, 159);
@almost-perfect-color: rgb(197, 54, 54);
@perfect-color: rgb(255, 159, 76);
@great-color: rgb(161, 48, 141);
#content {
  .header {
    background-color: #fff;
  }
  #logo {
    width: 160px;
    height: 100%;
    background-image: url('./public/logo.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .information {
    position: relative;
    cursor: pointer;
  }
}
.searchBarSelect {
  min-width: 130px;
}
.down_list_hover {
  min-width: 80px;
}
.down_list_hover:hover + .down_list {
  display: block;
}
.down_list:hover {
  display: block;
}
.down_list {
  display: none;
  transition: 0.3s;
  min-width: 100px;
  z-index: 999;
  width: 100%;
  height: 50px;
  overflow: hidden;
  background-color: #fff;
  margin-top: 7px;
  text-align: center;
  position: absolute;
  bottom: -50px;
}
.flex_start {
  display: flex;
}
.flex_1 {
  > div {
    flex: 1;
  }
}
//
.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex-left {
  display: flex;
}
.flex-right {
  display: flex;
  flex-direction: row-reverse;
}
.searchBarBox::after {
  display: block;
  content: '.';
  visibility: hidden;
  clear: both;
  width: 0;
  height: 0;
}
.searchBarBox {
  padding: 10px 30px;
  border-bottom: 1px solid rgb(221, 221, 221);
  background-color: rgb(244, 244, 244);
  font-size: 12px;
  .search_bar_tr {
    display: inline-block;
    margin-right: 16px;
    padding: 6px 0;
    .search_bar_text {
      width: 90px;
      margin-right: 4px;
      display: inline-block;
      text-align: right;
    }
  }
  .searchBarDate {
    width: 240px;
  }
}
.conetent_right {
  min-height: 100%;
  margin-left: 8px;
  background: #dddddd;
}
.paymentBox {
  > div {
    padding: 10px;
    > div {
      padding: 5px;
    }
    &:first-child {
      font-weight: 700;
    }
    &:last-child {
      margin: 10px;
      border: 1px solid #ccc;
      > div {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
.paymentTableBox {
  margin: 10px;
  > div {
    &:first-child {
      font-weight: 700;
    }
    &:last-child {
      padding: 10px;
    }
  }
}
.payments_table_headers {
  display: flex;
  width: 100%;
  > div {
    flex: 1;
  }
}
.payments_table_body {
  > div {
    display: flex;
    > div {
      flex: 1;
    }
  }
}

pre {
  margin-bottom: 0;
  overflow: hidden;
}

.contentFrom {
  overflow-y: auto;
  position: relative;
  height: 80%;
  padding: 10px 40px;
  margin-bottom: 50px;
  line-height: 32px;
}
.BatchRefund {
  display: flex;
  > div {
    flex: 1;
    &:first-child {
      flex: 2;
    }
  }
}
.row-dragging {
  z-index: 9999;
  display: flex;
  border: 1px solid #ccc;
  background-color: #ccc;
}

.row-dragging td {
  flex: 0 0 1;
  visibility: hidden;
  padding: 16px 24px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.courseTime {
  > .rolesSelect {
    flex: 1;
  }
}
.site-tag-plus {
  background: #fff;
  border-style: dashed;
}
.tips_box {
  display: flex;
  > div {
    display: flex;
    margin-right: 10px;
    align-items: center;
    > div {
      width: 15px;
      height: 15px;
    }
    &:first-child {
      > div {
        background-color: red;
      }
    }
    &:last-child {
      > div {
        background-color: #108ee9;
      }
    }
  }
}
.color_box {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.post-message-box {
  padding: 10px 0 20px 0;
  border-bottom: 1px solid #434343;
}
.send-post-message {
  margin: 10px 0;
  padding-bottom: 20px;
  border {
    top: 1px solid #434343;
    bottom: 1px solid #434343;
  }
  .DraftEditor-editorContainer > div {
    min-height: 200px;
  }
  position: relative;
  color: #fff;
  // font-weight: 700;
}
.post-message-body {
  padding-left: 80px;
  img {
    max-width: 100%;
    display: inline-block;
  }
  word-wrap: break-word;
}
.red-color {
  color: red;
}
.gray_color {
  color: gray;
}
.hidden-homework-users-box {
  > div {
    padding: 0 20px;
  }
}
.courseTemplate {
  .ant-modal-body {
    height: 600px;
    overflow-y: scroll;
  }
}
.identity-img {
  position: absolute;
  background-image: url(./public/blue_v.png);
  width: 14px;
  height: 14px;
  background-size: cover;
  bottom: 0;
  right: 0;
}
.fail-image {
  position: relative;
  display: inline-block;
  &::after {
    content: '待完善';
    position: absolute;
    background-color: @fail-color;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    top: 0;
    right: 0;
    padding: 2px;
  }
}
.almost-success-image {
  position: relative;
  display: inline-block;
  &::after {
    content: '差一点合格';
    position: absolute;
    background-color: @almost-success-color;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    top: 0;
    right: 0;
    padding: 2px 5px;
  }
}
.success-image {
  position: relative;
  display: inline-block;
  &::after {
    content: '合格';
    position: absolute;
    background-color: @success-color;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    top: 0;
    right: 0;
    padding: 2px 5px;
  }
}
.almost-perfect-image {
  position: relative;
  display: inline-block;
  &::after {
    content: '差一点优秀';
    position: absolute;
    background-color: @almost-perfect-color;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    top: 0;
    right: 0;
    padding: 2px;
  }
}
.perfect-image {
  position: relative;
  display: inline-block;
  &::after {
    content: '优秀';
    position: absolute;
    background-color: @perfect-color;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    top: 0;
    right: 0;
    padding: 2px;
  }
}
.great-image {
  position: relative;
  display: inline-block;
  &::after {
    content: '典型';
    position: absolute;
    background-color: @great-color;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    top: 0;
    right: 0;
    padding: 2px;
  }
}

.null-image {
  position: relative;
  display: inline-block;
  &::after {
    content: '未批改';
    position: absolute;
    background-color: gray;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    top: 0;
    right: 0;
    padding: 2px;
  }
}
.reupload {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.upload-container-success:hover .upload-container-action {
  display: flex;
  span {
    color: #ffffff;
  }
}
.upload-container-success {
  width: 104px;
  height: 104px;
  padding: 10px;
  position: relative;
  :hover .upload-container-action {
    display: flex;
    color: #fff;
  }
  .upload-container-action {
    display: none;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    color: #fff;
    > div {
      display: flex;
      justify-content: center;
      flex: 1;

      cursor: pointer;
    }
  }
  .upload-container-success-info {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}
.addplanclass {
  border: 1px dashed #ccc;
  text-align: center;
  line-height: 60px;
  height: 60px;
  width: 100%;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 10px;
}
.LiveIcon{
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

@primary-color: #eb2f96;