@bluecolor: #1890ff;
.textblod {
  font-weight: bold;
}
.item_bottom {
  margin-bottom: 8px;
}
.showForm {
  position: fixed !important;
  right: 20px;
  top: 20px;
  z-index: 999;
}
.bref_content {
  p {
    overflow: hidden;
  }
}
.drag-container {
  display: flex;
  padding: 0 5px;
  flex-direction: column;
  cursor: move;
  justify-content: center;
}
.formHeader {
  background: @bluecolor;
  height: 12vh;
  position: relative;
  .back_btn {
    position: absolute;
    top: 15px;
    height: 100%;
    padding: 10px;
    > span {
      color: #fff;
      cursor: pointer;
    }
  }
  .formHeaderNav {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    color: #fff;
    div {
      flex: 0 1 60px;
      line-height: 30px;
      cursor: pointer;
      text-align: center;
    }
  }
  .active {
    background-color: #fff;
    color: black;
  }
}
.trandsform {
  display: flex;
  padding: 20px;
  height: 88vh;
  font-size: 12px;
  .nothiddenall {
    color: #ff4d4f;
    text-align: center;
    font-size: 18px;
  }
  .ant-form-item-required::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
  .trandsform_Left {
    flex: 1;
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    box-shadow: 0px 1px 5px #ccc;
    flex-direction: column;
    .ant-btn {
      flex: 0 1 90px;
      margin: 5px;
    }
    .trandsform_button_box {
      > div {
        flex: 0 0 30%;
        text-align: center;
        flex-wrap: wrap;
        padding: 10px;
        margin: 5px 5px 5px 0;
        border: 1px dashed #ccc;
        cursor: grab;
      }
      display: flex;
      width: 100%;
      justify-items: start;
      flex-wrap: wrap;
    }
  }
  .trandsform_center {
    flex: 4;
    height: 100%;
    overflow-y: auto;

    .trandsform_active {
      background-color: rgba(0, 0, 0, 0.05);
    }
    .borderTop {
      &::before {
        content: '';
        width: 100%;
        height: 0.01rem;
        display: inline-block;
        border-top: 0.01rem dashed #dfdfdf;
      }
    }
    .trandsfrom_div {
      position: relative;
      min-height: 90px;
      .mask {
        position: absolute;
        height: 100%;
        z-index: 998;
        width: 100%;
        cursor: pointer;
      }
      .todo_components {
        position: absolute;
        right: 0;
        width: 100px;
        bottom: 0;
        height: 100%;
        .todo_components_left {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 5px;
          z-index: 999;
          font-size: 18px;
          .down_icon {
            &:hover {
              color: green;
            }
            margin-top: 20px;
            display: block;
          }
          .up_icon {
            &:hover {
              color: #118811;
            }
            display: block;
          }
        }
        .todo_components_bottom {
          position: absolute;
          bottom: 0;
          z-index: 999;
          right: 40px;
          font-size: 18px;
          .delete_icon {
            margin-left: 10px;
            &:hover {
              color: red;
            }
          }
          .copy_icon {
            &:hover {
              color: green;
            }
          }
        }
      }
    }
    .trandsform_center_content {
      // max-width: 700px;
      margin: 0 auto;
      padding: 0 10px 10px 10px;
      box-shadow: 0px 1px 5px #ccc;

      > div {
        .trandsfrom_div_content {
          margin: 0 30px;
          padding: 10px 0;

          .ant-input {
            margin-bottom: 10px;
          }
          .ant-cascader-input {
            margin-bottom: 0;
          }
          word-wrap: break-word;
        }
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
  .trandsform_Right {
    flex: 1;
    height: 100%;
    flex-direction: column;
    position: relative;
    overflow: auto;
    box-shadow: 0px 1px 5px #ccc;
    .transform_Right_title {
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
      border {
        top: 1px solid #ccc;
        bottom: 1px solid #ccc;
      }
    }
    .transFrom_right_fotter {
      width: 100%;
      position: absolute;
      bottom: 10px;
      height: 40px;
      line-height: 40px;
      background-color: #fff;
      z-index: 1000;
      left: 0;
      padding: 5px;
      border {
        top: 1px solid #ccc;
      }
    }
    .transform_Right_content {
      width: 100%;
      overflow-y: auto;
      padding: 40px 10px 50px 10px;
      position: absolute;
      background-color: #fff;
      top: 0;
      height: 100%;
      overflow-x: hidden;
    }
    .test_box {
      margin-top: 10px;
      border-bottom: 1px solid #ccc;
    }
    .ant-radio-wrapper {
      margin-right: 0;
    }
  }
}
.option_item {
  .option_item_div {
    position: relative;
    display: flex;
    margin-bottom: 20px;
    .tabInput {
      top: 30px;
      left: 0px;
      position: absolute;
    }
    .option_item_div_option1 {
      flex: 1;
      display: grid;
      padding-right: 5px;
      place-items: center;
    }
    .option_item_div_option2 {
      flex: 5;
    }
    .option_item_div_option3 {
      display: flex;
      align-items: center;
      > div {
        margin: 0 5px;
      }
      flex: 1;
    }
  }
}
.separator-line {
  display: none !important;
}
.dangerous_color {
  color: #ff4d4f;
}
.formHeaderTitle {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    text-align: center;
    color: #fff;
  }
}

@primary-color: #eb2f96;