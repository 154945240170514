@btn-font-size-sm: 12px;
@modaltitle-fontsize: 20px;
@global_gray: rgba(0, 0, 0, 0.45);

.gray_Color {
  color: @global_gray;
}
#root {
  height: 100%;
}
.testTips {
  font-size: 36px;
  font-weight: 500;
  color: red;
}
.not_select {
  -moz-user-select: none; /*mozilar*/
  -webkit-user-select: none; /*webkit*/
  -ms-user-select: none; /*IE*/
  user-select: none;
}

.custom-affix-wrapper {
  padding: 40px 0;
}

.ant-layout-sider-children {
  width: inherit;
}

.Bt_color {
  background: #58a1f5;
  color: #fff;
}
.link-color {
  cursor: pointer;
  color: #46a6ff;
}
.GroupClass {
  .ant-modal-body {
    height: 600px;
    overflow-y: scroll;
  }
}
.text_color {
  cursor: pointer;
  color: #58a1f5;
}
.color_orange {
  color: red;
}
#login {
  background-image: url('https://enroll-course.oss-cn-shanghai.aliyuncs.com/84b67ee441b679e4780b790060cfcf1c.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.ant-table-thead > tr {
  background: rgba(0, 0, 0, 0.05);
}
.ant-table {
  font-size: 12px !important;
}

#login .login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px 40px;
  width: 400px;
}
#login .login-form-forgot {
  color: rgb(24, 144, 255);
  cursor: pointer;
  float: right;
}
#login .login-form-button {
  width: 100%;
}
.err {
  color: #cd3333;
}
.success {
  color: #43cd80;
}
.ant-table-small {
  border: 0 !important;
}
.ant-table-thead tr:hover {
  background: rgba(0, 0, 0, 0.2);
}
.ant-table-row {
  line-height: 2.5;
}
.show_id {
  position: absolute;
  left: 50%;
  width: 600px;
  font-size: 12px;
  top: 50%;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 999;
  background: #fff;
  transform: translate(-50%, -50%);
}
.show_id_top {
  padding: 20px;
  height: 100px;
  width: 100%;
}
.show_id_right {
  padding: 20px;
  width: 200px;
  height: 400px;
}
.show_id_todo {
  position: absolute;
  bottom: 10px;
}
.show_id .ant-input {
  margin: 2px 4px;
  font-size: 12px;
}
.show_id .span_left {
  width: 42%;
  margin: 5px;
  display: inline-block;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-size: 12px !important;
}
#select_id {
  padding: 20px;
  max-height: 400px;
  position: absolute;
  background: #fff;
  overflow-y: scroll;
  border: 1px solid #ccc;
  z-index: 999;
}
#div_left {
  float: left;
  border-right: 1px solid #ccc;
}
#div_left div {
  padding: 5px;
}
#div_right {
  float: left;
  padding-left: 10px;
}
.show_id .ant-input {
  border: 0 !important;
}
.show_id input:disabled {
  color: black;
  background: #fff !important;
}
.clear {
  clear: both;
}
.clear::after {
  clear: both;
  content: '';
  display: block;
}

.form_span {
  display: block !important;
  margin-top: 8px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  line-height: 25px !important;
}
.text_center {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.down_list_Menu .ant-menu-item:hover {
  background-color: rgb(221, 221, 221);
  color: inherit !important;
}
.down_list_Menu .ant-menu-item {
  font-size: 13px !important;
}
.show_id_top h5 {
  font-size: 14px;
  line-height: 40px;
  border-bottom: 1px solid #ccc;
}
.close_bt {
  position: absolute;
  top: 15px;
  font-size: 20px;
  right: 17px;
  cursor: pointer;
}
.close_bt:hover {
  color: #1890ff;
}
#plus_series {
  width: 400px;
  height: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  position: absolute;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.plus_course_fathon {
  z-index: 999;
  width: 1150px;
  border-radius: 10px;
  height: 90%;
  border: 1px solid #ccc;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background: #f4f4f4;
  top: 5%;
  overflow: hidden;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.plus_course_foot {
  width: 100%;
  position: absolute;
  background: #f4f4f4;
  bottom: 0px;
  border-top: 1px solid #ccc;
  padding: 10px;
}
.plus_course_head {
  padding: 15px 20px;
  font-size: 20px;
  font-style: normal;
  border-bottom: 1px solid #ccc;
}
.ant-table-placeholder {
  background-color: inherit !important;
}
.ant-btn-lg {
  padding: 0 25px !important;
}
.ant-menu-submenu-title {
  height: 60px !important;
  line-height: 60px !important;
}

.Avatar {
  margin-right: 10px;
}
.Avatar:hover {
  transition: 0.5s all;
  background-color: #1890ff;
}

.pointer {
  cursor: pointer;
}
.pointerTodo {
  margin-right: 20px;
  font-size: 18px;
}
.ant-table {
  line-height: 2.5;
}
.ant-table-body {
  margin: 0 !important;
}
.content {
  position: relative;
}
.content .ant-table-content {
  background: rgb(244, 244, 244);
  min-height: 650px;
}

.ant-layout-has-sider {
  padding: 8px;
}
.ant-menu-inline .ant-menu-item {
  margin: 0 0 !important;
}
.plus_course {
  overflow-y: auto;
  height: 750px;
  font-size: 12px;
  padding: 20px 60px;

  overflow-y: auto;
}
.formText label {
  max-width: 70%;
  margin-bottom: 20px;
  display: inline-block;
}
.formText label:first-child {
  margin-left: 10%;
  width: 40%;
}
.plus_course .div_son {
  width: 50%;
  height: 40px;
  line-height: 40px;
  margin-bottom: 20px;
  float: left;
}

.formContentTitle {
  color: gray;
}
.contentFrom div:last-child {
  border: none;
}
.formContent {
  margin-top: 20px;
  border-bottom: 1px solid #000000;
}
.row_formText label {
  &:first-child {
    font-weight: bold;
  }
  display: block;
  min-height: 30px;
  width: 100%;
}
.formContent .ant-form-item-control-input,
.ant-form-item-explain {
  width: 90%;
  // margin-left: 10%;
}
.formContent .ant-form-item-extra {
  width: 90%;
  margin-left: 10%;
}
.editorContent .ant-form-item-control-input-content,
.editorContent .ant-form-item-control-input {
  width: 100% !important;
  margin-left: 0 !important;
}
.label-margin-bottom {
  margin-bottom: 5px;
}
.trandsform_Right .ant-form-item-label {
  margin: 5px 0;
}
.formContent .ant-form-item-label label {
  min-width: 115px;
  margin-left: 40px;
}
.limitTipsMaodal .ant-form-item-label label {
  min-width: 0;
  margin-left: 0;
}
.plus_from {
  position: relative;
  margin-top: 20px;
}
.plus_course .div_son .ant-form-item-control {
  float: right;
  width: 200px;
}
.div_son .ant-form-item-control-input {
  position: absolute;
  right: 40px;
  width: 225px;
}
.ant-layout-sider .ant-menu-submenu-title,
.ant-menu-item {
  font-size: 12px !important;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  height: 50px;
  line-height: 50px;
}
.form_title {
  width: 100% !important;
  font-size: 12px !important;
}
.form_title .ant-input,
.ant-table-row {
  line-height: 1.7;
  font-size: 12px;
}
.component_head {
  margin-bottom: 10px;
  font-size: 12px;
}
.rolesComponents {
  min-width: 1265px;
  margin-top: 30px;
}
.rolesListBox {
  overflow: hidden;
  margin-top: 30px;
}
.rolesListBox div:last-child {
  margin-right: 0;
}
.rolesListBox .rolesListBoxSon {
  float: left;
  width: 200px;
  height: 100px;
  margin-right: 25px;
  text-align: center;
  border: 1px solid #ccc;
}
.rolesSelect {
  width: 180px;
  float: left;
  margin-left: 10px;
}
.rolesDiv {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 10px;
  height: 410px;
  overflow-y: auto;
}
.rolesSelect h3 {
  text-align: center;
  font-style: normal;
  line-height: 40px;
  color: #fff;
  background-color: #0084ff;
}
.searchBox {
  margin-left: 40px;
  float: left;
}
.searchBoxSearchBar {
  width: 900px;
}
.searchBoxSearchBarInput {
  width: 600px;
  float: left;
}
.searchBoxSearchBarButton {
  float: left;
  margin-left: 40px;
}
.tabMenus {
  position: absolute;
  top: 8px;
  color: #1890ff;
  right: -28px;
  z-index: 999;
  cursor: pointer;
  font-size: 20px;
}
.delet_bt {
  position: absolute;
  right: 18px;
  z-index: 9;
}
.plus_title {
  margin: 10px 0px;
  text-indent: 20px;
  border-bottom: 1px solid rgb(204, 204, 204);
  line-height: 35px;
  font-weight: bold;
}
.div_son_overflow {
  width: 100% !important;
}
.forms_table .ant-form-item-control {
  width: 100% !important;
}
.qqGroup .ant-input {
  width: 100% !important;
}
.plus_group_bt {
  display: inline-block;
  width: 40px;
  position: relative;
  top: 2px;
  text-align: center;
  cursor: pointer;
}
.plus_group_bt:hover {
  color: #40a9ff;
}
.type_button {
  display: block;
  margin-bottom: 20px;
}
.bt {
  cursor: pointer;
}

.bt:hover {
  color: #40a9ff;
}
.bf-media-toolbar {
  font-family: sans-serif !important;
}
.bt_parent {
  display: inline-block;
  padding: 0 20px 0 0;
  text-align: left;
}
.blue_color {
  color: #40a9ff;
}
.left {
  float: left;
}
.right {
  float: right;
}
.GroupBoxText {
  width: 60%;
  margin: 10px 0;
  text-align: center;
}
.GroupBox {
  padding: 20px;
  height: 200px;
  overflow-y: auto;
  width: 60%;
  border: 1px solid #ccc;
}
.clear_both::after {
  content: '';
  width: 0;
  border: 0;
  height: 0;
  display: block;
  clear: both;
}
.bt_parent button {
  font-size: 12px;
  border: 0px;
  position: relative;
  bottom: 1px;
}
.flex_container {
  display: flex;
  margin: 4px 0;
  justify-items: center;
  > span {
    font-size: 22px;
  }
  > div {
    flex: 1;
    width: 0;
  }
}
.flex_left {
  text-align: left;
  padding: 0 10px;
  flex: 1;
}
.flex_right {
  text-align: right;
  padding: 0 10px;
  flex: 1;
}
.flex_center {
  text-align: center;
  padding: 0 10px;
  flex: 1;
}
.global_loading {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #40a9ff;
  font-size: 24px;
  transform: translate(-50%, -50%);
}
.search_bar_div {
  display: inline-block;
}
.margin-auto {
  margin: 0 10px;
}
.other-container {
  width: 100%;
  .flex_between {
    padding: 5px 0;
  }
}
.operate_bt {
  margin: 2px 10px;
  display: inline-block;
  text-align: left;
  color: #1890ff;
  cursor: pointer;
}
.image_show_bt {
  top: 0;
  position: absolute;
  z-index: 998;
  cursor: pointer;
  width: 50%;
  height: 98%;
}

.image_box_rotate {
  position: absolute;
  right: 80px;
  top: 20px;
  z-index: 999;
  color: #fff;
  background: #ccc;
  font-size: 30px;
  cursor: pointer;
}

.image_box_close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 999;
  color: #fff;
  background: #ccc;
  font-size: 30px;
  cursor: pointer;
}
.image_box_image img {
  width: 100%;
}
.image_box_image {
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  overflow-y: auto;
  height: 700px;
}
.image_box {
  width: 930px;
  overflow: hidden;
  transition: 0.5s all;
  background: black;
  position: fixed;
  height: 100%;
  top: 50%;
  z-index: 9999;
  left: 50%;
  transform: translate(-50%, -50%);
}
.table_image {
  width: 120px;
  height: 70px;
}
.cc_bar {
  width: 620px;
  display: flex;
  margin: 0 auto;
}
.cc_bar_active {
  background: #fff !important;
}
.cc_bar div {
  cursor: pointer;
  flex: 1;
  background: #ccc;
  line-height: 40px;
  text-align: center;
}
.cc_bar_2 {
  position: relative;
  margin: 0 0 30px 0;
}
.cc_bar_2 label:first-child {
  display: inline-block;
  width: 100px;
}
.cc_bar_2 input {
  width: 200px;
}
.input_box textarea {
  width: 100%;
  height: 430px;
}
.input_box {
  margin-top: 20px;
  position: relative;
}
.input_box2 {
  margin-top: 20px;
  position: relative;
  textarea {
    width: 100%;
    height: 430px;
    padding: 10px;
  }
}
.input_box div {
  position: absolute;
  right: 2px;
  background: #fff;
  bottom: 7px;
}
.cc_bar_2_right {
  position: absolute;
  right: 0;
  top: 0;
}
.input_box textarea {
  padding: 5px;
}
.cc_bar_btn {
  margin: 40px 0;
}
.cc_bar_btn .ant-btn {
  margin-right: 30px;
}
.searchBarInput {
  width: 145px;
}

.bottomNub {
  display: block;
  position: relative;
}
.bottomNub span {
  width: 60px;
  display: block;
  text-align: right;
  // background: #fff;
  position: absolute;
  bottom: 4px;
  right: 13px;
}
.updateRecord {
  position: absolute;
  width: 800px;
  height: 600px;
  padding: 20px;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.box_s {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.box_s .ant-btn {
  position: absolute;
  right: -70px;
}
.innerHTMLDOM img {
  width: 100%;
}
.braft-output-content {
  padding-top: 20px;
  p {
    display: block;
    padding: 8px 0;
    margin-bottom: 0 !important;
  }
}
.tree-transfer {
  width: 100%;
  display: flex;
  .ant-transfer-list {
    flex: 1 1 50%;
    height: 300px;
    overflow-y: scroll;
    background-color: #fff;
  }
}
.packenrollclass {
  display: flex;
  > span {
    flex: 1;
  }
}
.savebutton {
  display: flex;
  justify-content: center;
}
.savebutton .ant-form-item-control-input-content {
  display: flex;
}
.banselect {
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
}
.orderplanstyle {
  font-weight: 700;
}
.orderlistscroll {
  max-height: 800px;
  overflow: hidden;
  overflow-y: scroll;
}
.remarkscroll {
  max-height: 100px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0 5px;
}
.order_title {
  padding: 20px 30px;
  border-bottom: 1px solid #dddddd;
}
.order_search_box {
  background-color: #f4f4f4;
  padding: 10px 0;
  overflow-x: scroll;
  width: 100%;
}
.order_flex {
  display: flex;
  flex-direction: row;

  padding: 0 30px;
}
.result_order_style {
  width: 500px;
  height: 600px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 11px;
}
.order_exchange {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.orderstyle {
  border: 1px solid #ccc;
  padding: 5px 10px;
  background-color: #fff;
}
.orderstyle_result {
  border: 1px solid #ccc;
  padding: 5px 10px;
  background-color: #fff;
}
.arrow_flex {
  display: flex;
  font-size: 36px;
  align-items: center;
  margin: 0 10px;
}

.resgistHeaderBox {
  background-color: #fff;
  padding: 20px 10px 10px 10px;
  margin-right: 2px;

  .searchArea1 {
    background-color: rgba(48, 48, 48, 0.05);
    input {
      background-color: rgba(48, 48, 48, 0.05);
    }
  }
  .resgistSearchBox {
    display: flex;
    flex-direction: column;
    border-bottom: 3px solid rgb(244, 244, 244);
  }
  .borderclickbg {
    background-color: rgba(48, 48, 48, 0.05);
    input {
      background-color: rgba(48, 48, 48, 0.05);
    }
  }
  .resgistBorder {
    border-radius: 10px;
    border: 2px solid #d8d8d8;
    margin: 10px 0;
    padding: 10px 15px;
    display: flex;
    flex-wrap: wrap;
    .search_bar_tr {
      margin: 5px;
      > span {
        text-align: right;
        display: inline-block;
        width: 100px;
        margin-right: 4px;
      }
    }
  }
}
.searchBoxReset {
}
.activePriceTable {
  border-top: 1px solid #ccc;
  padding-top: 15px;
  .liftprice::before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}
.addItemBtn {
  width: 100%;
  border: 1px dashed #ccc;
}
.editItemBtn {
  width: 100%;
  margin-top: 10px;
}
.activePriceClass {
  color: #ff00b2;
}
#redeemId {
  .ant-form-item-control-input-content {
    display: flex;
  }
}
.couponInfo {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  .couponShowStyle {
    color: #9e9e9e;
    font-weight: 500;
  }
  .couponTitleStyle {
    font-weight: 500;
    margin-right: 5px;
  }
}
.buildingLoad {
  margin-left: 10px;
  font-size: 14px;
  margin-top: 5px;
  color: #eb2f96;
}
.reduceType .ant-form-item-control-input-content {
  display: flex;
}
.reduceConfigstyle {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 42px;
  margin-top: -20px;
}
.classFont {
  font-weight: bold;
}
.unitStyle{
  position: absolute;
  right: -30px;
  top: 5px;
  cursor: pointer;
}
.contractBoxText {
  position: absolute;
  left: 70%;
  display: flex;
  flex-direction: column;
  top: 0;
}
.check_historycontract {
  position: absolute;
  top: 0;
  right: 0;
}
.contractContent {
  width: 100%;
}
.contractContentBox {
  width: 600px;
}
.precourseTips {
  position: absolute;
  right: -56px;
  top: 5px;
  color: #eb2f96;
  cursor: pointer;
}
.userTagStyle {
  background-color: #8a38f5;
  color: #fff;
  border-radius: 4px;
  margin-bottom: 3px;
  text-align: center;
  padding: 5px 10px;
}
.selfUserLabels {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.tooltopStyle {
  display: flex;
  flex-direction: column;
}

.paymentTableList {
  margin-top: 15px;
}
.UserInputBox {
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #d8d8d8;
  min-height: 150px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
  padding: 10px 20px;
}
.UserInputContent {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}
.switchOpen .ant-modal-confirm-body {
  display: block !important;
}
.m-error-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.xiaoeheader {
  display: flex;
  justify-content: space-between;
}
.xiaoeFigure {
  width: 160px;
  height: 80px;
  object-fit: cover;
}
.addClassInCm {
  .ant-modal-content {
    padding: 20px 24px 5px 24px;
  }
}
.clearBindingText {
  font-size: @modaltitle-fontsize;
}
.course_channel_header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.channel_head_bg{
  background-color: #F0F0F0;
  display: flex;
  padding: 5px;
  margin-top: 10px;
  >div{
    flex: 1;
     text-align: center;
  }
  >div:nth-child(2) {
              flex: 2;
    }
    >div:nth-child(1) {
              flex: 2;
    }
  
}
.fadechannel_table_AddBox{
  padding: 10px;
  margin-top: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}

.tableBody{
  display: flex;
  >div{
    flex: 1;

    text-align: center;
  }
  >div:nth-child(1) {
              flex: 2;
    }
  >div:nth-child(2) {
              flex: 2;
              text-align: left;
              max-width: 269px;
    }
}

.Contractheader{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  .confirmway{
    margin-right: 30px;
  }
}
.contractfooter{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

@primary-color: #eb2f96;