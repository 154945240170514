.rolesSelect_rule {
  flex: 0 1 42%;
  max-width: 42%;
  margin: 10px 10px 0 0;
}
.ruleDiv {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 10px;
  height: 200px;
  overflow-y: auto;
  margin-bottom: 8px;
  .ant-tree-title {
    word-break: break-all;
  }
}
.rolesSelect_rule h3 {
  text-align: center;
  font-style: normal;
  line-height: 40px;
  color: #fff;
  background-color: #0084ff;
}
.rule_box {
  display: flex;
  justify-items: start;
  font-size: 12px;
  .rule_boxs_div {
    overflow-y: auto;
    height: 100%;
    padding: 10px;
    .active {
      border: 1px solid #0084ff;
    }
    .wrong_message {
      padding: 10px;
      margin-bottom: 10px;
      background-color: #fadb14;
    }
    .error_message {
      padding: 10px;
      margin-bottom: 10px;
      background-color: #ffa39e;
    }
  }

  > div {
    flex: 1;
    margin: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
  }

  .rule_boxs {
    position: relative;
  }
  .right_form_button {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: grid;
    place-items: center;
    padding: 10px;
    background-color: #fff;
    border {
      top: 1px solid #ccc;
      bottom: 1px solid #ccc;
    }
  }
}
.right_form_button_content {
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.add_box {
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  border: 1px dotted grey;
  justify-content: center;
  align-items: center;
}
.pointer {
  cursor: pointer;
}
.add_box_left {
  margin-bottom: 10px;
  display: flex;
  position: relative;
  min-height: 90px;
  border: 1px dotted grey;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  > section {
    display: flex;
    padding: 5px 50px 5px 10px;
    > div {
      text-align: left;
    }
    > h3 {
      display: block;
      width: 50px;
      font-size: 14px;
    }
  }
  .add_box_left_todo {
    position: absolute;
    right: 20px;
    z-index: 2;
    font-size: 24px;
  }
  .plus {
    color: green;
  }
  .deleteOne {
    color: red;
  }
}
.right_rule_box {
  display: flex;
  position: relative;
}
.right_rule_box_delete {
  color: red;
  position: absolute;
  bottom: 15px;
  cursor: pointer;
  right: 10px;
}
.edit_columns {
  display: flex;
  align-items: center;
  > span {
    display: flex;
    margin-right: 20px;
    align-items: center;
    text-align: left;
    > span {
      margin-left: 20px;
      cursor: pointer;
    }
  }
  > input {
    width: 100px;
  }
}

@primary-color: #eb2f96;